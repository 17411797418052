
import { defineComponent, ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper"; 
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
SwiperCore.use([Navigation, Pagination, A11y]);
export default defineComponent({
  name: "sign-in",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
});
